import { action, observable } from 'mobx';
import { isShortZipCodeOnType } from 'utils/form/asserts';
import {
  BillSamples,
  Classification,
  Commodity,
  DeliveryServiceInterface,
  State,
  Utility,
  ZipCodeResponse,
  Zone,
} from 'services/delivery/types';
import { AnnualUsageUnit, CustomerType } from 'services/orders/types';
import {
  EVENT_TRIGGER_PARAM,
  triggerRetailEnterZipCodeEvent,
  triggerRetailSelectRateClassEvent,
} from 'config/seo/events';
import {
  changedUtilityAndCommodity,
  highlightUtility,
  utilitiesByCommodity,
  utilitiesByCustomerType,
} from 'utils/retail/plansFilter';
import { PlansFilter } from 'utils/retail/plans';
import { HumanViewUnit } from 'utils/data/types';
import { DEFAULT_ELECTRICITY_ANNUAL_USAGE, DEFAULT_GAS_ANNUAL_USAGE, DEFAULT_STATE, } from 'config/retail';
import { getStateByShort } from 'utils/data';

interface Lists {
  zone: Zone[];
  utility: Utility[];
  commodity: Commodity[];
  classifications: Classification[];
}

interface Data {
  zipCodeResponse: ZipCodeResponse;
  isStateReplacedWithDefault: boolean;
  isLoadingFilter: boolean;
  isLoadingZipCode: boolean;
}

const initialForm: PlansFilter = {
  customerType: CustomerType.RESIDENTIAL,
  zipCode: '',
  state: null,
  commodity: Commodity.ELECTRICITY,
  utility: null,
  zone: null,
  rateClass: null,
  annualUsage: '',
  unit: AnnualUsageUnit.KWH,
};

class PlansFilterStore {
  form: PlansFilter = observable(initialForm);

  lists: Lists = observable({
    zone: [],
    utility: [],
    commodity: [],
    classifications: [],
  });

  data: Data = observable({
    zipCodeResponse: null,
    isLoadingFilter: true,
    isLoadingZipCode: false,
    isStateReplacedWithDefault: false,
  });

  annalUsageValues = observable({
    [Commodity.ELECTRICITY]: null,
    [Commodity.GAS]: null,
  });

  private stateUtilityList: Utility[] = [];

  constructor(private readonly deliveryService: DeliveryServiceInterface) {
  }

  get isLoadingFilter() {
    return this.data.isLoadingFilter;
  }

  get isLoadingZipCode() {
    return this.data.isLoadingZipCode;
  }

  setIsLoadingFilter = (value: boolean) => {
    this.data.isLoadingFilter = value;
  };

  get zipCodeResponse() {
    return this.data.zipCodeResponse;
  }

  getDefinedState = (): State => {
    if (this.zipCodeResponse) {
      const { state } = this.zipCodeResponse;
      return getStateByShort(state);
    }
    if (this.form.state) {
      return this.form.state;
    }
  };

  getStates = async (): Promise<State[]> => {
    return await this.deliveryService.getDeregulatedStates();
  };

  getDefaultState = async (): Promise<State> => {
    const states = await this.getStates();
    return states.find((item) => item.state === DEFAULT_STATE) || states[0];
  };

  hasZipCode = (): boolean => {
    return this.form.zipCode.length >= 5;
  };

  hasState = (): boolean => {
    return !!this.form.state;
  };

  hasUtility = (): boolean => {
    return !!this.form.utility;
  };

  getUnit = (): string => {
    const unit = this.unit;
    if (unit) {
      return unit;
    }
    switch (this.form.commodity) {
      case Commodity.ELECTRICITY:
        return HumanViewUnit.KWH;
      case Commodity.GAS:
        return HumanViewUnit.THERM;
      default:
        return '';
    }
  };

  getAnnualUsage = (strict = true) => {
    const { commodity, customerType } = this.form;
    if (strict) {
      if (this.annalUsageValues[commodity] === null) {
        return commodity === Commodity.ELECTRICITY
          ? DEFAULT_ELECTRICITY_ANNUAL_USAGE[customerType]
          : DEFAULT_GAS_ANNUAL_USAGE[customerType];
      }
    } else if (!this.annalUsageValues[commodity]) {
      return commodity === Commodity.ELECTRICITY
        ? DEFAULT_ELECTRICITY_ANNUAL_USAGE[customerType]
        : DEFAULT_GAS_ANNUAL_USAGE[customerType];
    }
    return this.annalUsageValues[commodity];
  };

  onChangeCustomerType = action((value: CustomerType) => {
    this.form.customerType = value;
    this.form.utility = initialForm.utility;
    this.filterUtilitiesByCustomerType();
    this.updateUtilityAndCommodity();
    this.onChangeUtility(this.getHighlightUtility());
  });

  onChangeZipCode = action((zipCode: string) => {
    if (zipCode.length > 5) {
      return;
    }

    if (!isShortZipCodeOnType(zipCode)) {
      this.data.zipCodeResponse = null;
      return;
    }

    this.form.state = initialForm.state;
    this.form.utility = initialForm.utility;
    this.form.zone = initialForm.zone;
    this.form.rateClass = initialForm.rateClass;
    this.form.zipCode = zipCode;
    this.lists.zone = [];
    this.lists.utility = [];
    this.lists.classifications = [];

    if (zipCode.length === 5) {
      this.data.isLoadingZipCode = true;
      this.data.zipCodeResponse = null;
      triggerRetailEnterZipCodeEvent(zipCode);

      return this.deliveryService
        .getZipCode(zipCode)
        .then(async (zipCodeResponse) => {
          this.data.zipCodeResponse = zipCodeResponse;
          try {
            this.data.zipCodeResponse.defaultUtility = (await this.deliveryService.getUtilityByZipCode(zipCode))[0];
          } catch (e) {
          }
          return this.deliveryService.getStateByCode(zipCodeResponse.state);
        })
        .then(async (state) => {
          if (state) {
            return this.onChangeState(state, false,);
          }
          throw new Error('State is not deregulated');
        })
        .catch(async () => {
          await this.onChangeState(await this.getDefaultState(), true);
        })
        .finally(() => {
          this.data.isLoadingZipCode = false;
        });
    }
  });

  onChangeState = action(
    async (
      state: State,
      replaceWithDefault = false,
    ) => {
      this.data.isLoadingZipCode = true;
      this.data.isStateReplacedWithDefault = replaceWithDefault;
      this.form.state = state;
      try {
        this.stateUtilityList =
          state && state.state ? await this.deliveryService.getUtilityByState(state.state) : [];
      } catch (e) {
        this.data.isLoadingZipCode = false;
        this.stateUtilityList = [];
      }

      this.filterUtilitiesByCustomerType();
      this.updateUtilityAndCommodity();

      this.data.isLoadingZipCode = false;
    }
  );

  onChangeUtilityList = action(() => {
    this.onChangeUtility(this.getHighlightUtility());
  });

  onChangeUtility = action((utility: Utility) => {
    this.lists.zone = [];
    this.lists.classifications = [];
    if (utility && utility.commodities) {
      const commodity = utility.commodities.find(
        (c) => c.commodity.toLowerCase() === this.form.commodity.toLowerCase()
      );
      this.lists.zone = commodity?.zones || [];
      const classifications = commodity?.classifications || [];
      this.lists.classifications = classifications.filter((classification) => {
        return (
          classification.customerTypes &&
          classification.customerTypes.includes(this.form.customerType.toUpperCase())
        );
      });
    }
    this.form.utility = utility;

    let defaultZone = utility?.commodities[0]?.zones && utility?.commodities[0]?.zones.length ?
      utility?.commodities[0]?.zones[0] :
      this.lists.zone.length > 0 ? this.lists.zone[0] : null;

    let defaultClassification = utility?.commodities[0]?.classifications ?
      utility?.commodities[0]?.classifications.find((cl) => cl.customerTypes.includes(this.form.customerType)) :
      this.lists.classifications.length > 0 ? this.lists.classifications[0] : null;

    if (utility?.utilityId === this.zipCodeResponse?.defaultUtility?.utilityId) {
      if (
        this.zipCodeResponse?.defaultUtility?.commodities[0]?.zones &&
        this.zipCodeResponse?.defaultUtility?.commodities[0]?.zones.length
      ) {
        defaultZone = this.zipCodeResponse?.defaultUtility?.commodities[0]?.zones[0];
      }
      if (this.zipCodeResponse?.defaultUtility?.commodities[0]?.classifications) {
        defaultClassification = this.zipCodeResponse?.defaultUtility?.commodities[0]?.classifications.find((cl) => cl.customerTypes.includes(this.form.customerType))
      }
    }

    this.onChangeZone(defaultZone);
    this.onChangeRateClass(defaultClassification);
  });

  onChangeCommodity = action((commodity: Commodity) => {
    this.form.commodity = commodity;
    this.filterUtilitiesByCustomerType();
    this.lists.utility = utilitiesByCommodity(
      this.lists.utility,
      this.form.commodity,
      this.form.customerType
    );
    this.onChangeUtility(this.getHighlightUtility());
  });

  onChangeZone = action((zone: Zone) => {
    this.form.zone = zone;
  });

  onChangeRateClass = action(
    (rateClass: Classification, trigger = EVENT_TRIGGER_PARAM.userInteraction) => {
      const other =
        !this.form.rateClass ||
        this.form.rateClass.classificationId !== rateClass?.classificationId;
      this.form.rateClass = rateClass;
      if (!!rateClass && other) {
        triggerRetailSelectRateClassEvent(rateClass.name, trigger);
      }
    }
  );

  onChangeTotalAnnualUsage = action((totalAnnualUsage: string) => {
    this.annalUsageValues[this.form.commodity] = totalAnnualUsage;
  });

  onSelectUtility = action((utilityId: string) => {
    this.onChangeUtility(this.lists.utility.find((utility) => utility.utilityId === utilityId));
  });

  onSelectRateClass = action((id: string, trigger = EVENT_TRIGGER_PARAM.userInteraction) => {
    this.onChangeRateClass(
      this.lists.classifications.find((item) => item.classificationId === id),
      trigger
    );
  });

  onSelectZone = action((id: string) => {
    this.onChangeZone(this.lists.zone.find((item) => item.zoneId === id));
  });

  private get unit(): AnnualUsageUnit | undefined {
    if (!this.form.utility || !this.form.commodity) {
      return;
    }

    for (const c of this.form.utility.commodities) {
      if (c.commodity.toLowerCase() === this.form.commodity.toLowerCase()) {
        return c.unit;
      }
    }
  }

  get billSamples(): BillSamples | { [key: string]: string } {
    if (!this.form.utility || !this.form.commodity || !this.form.customerType) {
      return {};
    }

    for (const c of this.form.utility.commodities) {
      if (c.commodity.toLowerCase() === this.form.commodity.toLowerCase()) {
        if (!c.billSamples) {
          return {};
        }
        return c.billSamples.find((item) => item.customerType == this.form.customerType) || {};
      }
    }
    return {};
  }

  protected updateUtilityAndCommodity = action(() => {
    const { anotherCommodity, commodityList, utilityList } = changedUtilityAndCommodity(
      this.lists.utility,
      this.form.commodity,
      this.form.customerType
    );
    this.lists.commodity = commodityList;
    if (utilityList) {
      this.lists.utility = utilityList;
    } else {
      this.onChangeCommodity(anotherCommodity);
    }
  });

  protected getHighlightUtility = (): Utility | null => {
    const utility = highlightUtility(this.zipCodeResponse, this.lists.utility, this.form.commodity);
    if (utility) {
      return utility;
    }
    return this.lists.utility[0] || null;
  };

  protected filterUtilitiesByCustomerType = () => {
    if (this.form.customerType) {
      this.lists.utility = utilitiesByCustomerType(this.stateUtilityList, this.form.customerType);
      if (this.form.utility) {
        this.onSelectUtility(this.form.utility.utilityId);
      }
    }
  };
}

export default PlansFilterStore;
