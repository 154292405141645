import { action, observable } from 'mobx';
import {
  GetBestPlansRequest,
  GetPlansRequest,
  Plan,
  PlansQueryFilter,
  QuotesServiceInterface,
} from 'services/quotes/types';
import { BillSamples, DeliveryServiceInterface, State, Utility } from 'services/delivery/types';
import { CommercialSessionInterface } from 'services/retail/commercial/types';
import { ResidentialSessionInterface } from 'services/retail/residential/types';
import {
  calcEstimatesSavings,
  calcMinEffectiveDate,
  formatEffectiveDate,
  humanFormatEffectiveDate,
} from 'utils/retail/plans';
import { PLANS_DEFAULT_ANNUAL_USAGE, PLANS_DEFAULT_SORT, PLANS_PAGE_SIZE } from 'config/quotes';
import { FormValidators } from 'utils/form/types';
import { isRequired, isShortZipCode } from 'utils/form/validators';
import { CustomerType } from 'services/orders/types';

interface Errors {
  error: boolean;
  annualUsage: boolean;
  priceToCompare: boolean;
}

class QuotesStore {
  plans = observable({
    data: [],
    total: 0,
    isLoading: false,
    sort: PLANS_DEFAULT_SORT,
    pageSize: PLANS_PAGE_SIZE,
    page: 1,
    noPlans: false,
  });

  staticFilterData = observable({
    utility: null,
    state: null,
    showEstSavings: false,
    tooltipHover: false,
  });

  bestPlans = observable({
    data: [],
    isLoading: false,
  });

  filter: PlansQueryFilter = observable({} as PlansQueryFilter);
  errors: Partial<Errors> = observable({});

  public readonly minEffectiveDate: Date;

  constructor(
    private readonly quoteService: QuotesServiceInterface,
    private readonly deliveryService: DeliveryServiceInterface,
    private readonly commercialSession: CommercialSessionInterface,
    private readonly residentialSession: ResidentialSessionInterface
  ) {
    this.minEffectiveDate = calcMinEffectiveDate();
    this.filter.effectiveDate = humanFormatEffectiveDate(this.minEffectiveDate);
  }

  get isResidential(): boolean {
    return this.filter.customerType === CustomerType.RESIDENTIAL;
  }

  startOrderSession = action(async (plan: Plan, backUrl: string) => {
    const state = await this.getStateByCode();
    const utility = await this.getUtility();
    const session = this.isResidential ? this.residentialSession : this.commercialSession;
    const sessionHasStarted = session.hasStarted();

    session.setSelectedPlan(plan);
    session.setAnnualUsage({
      amount: this.filter.annualUsage
        ? Number(this.filter.annualUsage)
        : PLANS_DEFAULT_ANNUAL_USAGE[this.filter.commodity],
      unit: this.filter.unit,
    });
    session.setBackUrl(backUrl);
    session.setEffectiveDate(this.filter.effectiveDate);
    session.setBillSamples(this.getBillSamples(utility as Utility) as BillSamples);

    if (sessionHasStarted) {
      return;
    }

    if (state) {
      session.setMailingBillingInformation({ state, zipCode: this.filter.zipCode });
      if (this.isResidential) {
        this.residentialSession.setUtilityAccountInformation({
          state,
          zipCode: this.filter.zipCode,
        });
      } else {
        this.commercialSession.setUtilityAccountInformation([
          { state, zipCode: this.filter.zipCode },
        ]);
      }
    }
  });

  populateFilter = action((data: Partial<PlansQueryFilter>): void => {
    for (const [name, value] of Object.entries(data)) {
      this.setFilterField(name as keyof PlansQueryFilter, value as string);
    }
  });

  setFilterField = action((name: keyof PlansQueryFilter, value: unknown): void => {
    this.filter[name] = value as never;
    if (['annualUsage', 'priceToCompare'].includes(name)) {
      this.refreshEstimationSaving();
    }
  });

  searchPlans = action(async (): Promise<void> => {
    this.plans.isLoading = true;
    try {
      const { plans, total } = await this.quoteService.getPlans(this.createPlansRequest());
      this.plans.data = this.fillEstimatesSavings(plans);
      this.plans.total = Number(total);
      this.plans.noPlans = Number(total) === 0;
    } catch (e) {
      this.plans.noPlans = true;
    } finally {
      this.plans.isLoading = false;
    }
  });

  searchBestPlans = action(async (): Promise<void> => {
    this.bestPlans.isLoading = true;
    try {
      const { bestPlans } = await this.quoteService.getBestPlans(this.createBestPlansRequest());
      this.bestPlans.data = this.fillEstimatesSavings(bestPlans);
    } catch (e) {
      this.plans.noPlans = true;
    } finally {
      this.bestPlans.isLoading = false;
    }
  });

  createBestPlansRequest = (): GetBestPlansRequest => {
    const request = {
      ...this.filter,
      effectiveDate: formatEffectiveDate(this.filter.effectiveDate),
    };
    delete request.term;
    return request;
  };

  createPlansRequest = (): GetPlansRequest => {
    return {
      ...this.createBestPlansRequest(),
      term: this.filter.term,
      page: this.plans.page,
      size: PLANS_PAGE_SIZE,
      sort: this.plans.sort || PLANS_DEFAULT_SORT,
    };
  };

  getUtility = async (): Promise<Utility | Partial<Errors> | void> => {
    try {
      const utility = await this.deliveryService.getUtilityByState(this.filter.state);
      return utility.find((item) => item.utilityId === this.filter.utilityId);
    } catch (e) {
      return { error: true };
    }
  };

  getStateByCode = (): Promise<State | void> => {
    return this.deliveryService.getStateByCode(this.filter.state);
  };

  getBillSamples(utility: Utility): BillSamples | { [key: string]: string } {
    for (const c of utility.commodities) {
      if (c.commodity.toLowerCase() === this.filter.commodity.toLowerCase()) {
        if (!c.billSamples) {
          return {};
        }
        return c.billSamples.find((item) => item.customerType == this.filter.customerType) || {};
      }
    }
    return {};
  }

  fillEstimatesSavings = (plans: Plan[]): Plan[] => {
    const priceToCompare = Number(this.filter.priceToCompare);
    return plans.map((item) => {
      const { term, price } = item;
      const amount = priceToCompare
        ? calcEstimatesSavings(priceToCompare, Number(this.filter.annualUsage), price, term)
        : 0;
      return { ...item, estimatedSavings: { ...item.estimatedSavings, amount } };
    });
  };

  refreshEstimationSaving = action((): void => {
    this.plans.data = this.fillEstimatesSavings(this.plans.data);
    this.bestPlans.data = this.fillEstimatesSavings(this.bestPlans.data);
    if (Number(this.filter.priceToCompare)) {
      this.errors.annualUsage = !this.filter.annualUsage;
      this.staticFilterData.showEstSavings = true;
    } else {
      this.errors.annualUsage = false;
      this.staticFilterData.showEstSavings = false;
    }
  });

  protected get validators(): FormValidators<Partial<PlansQueryFilter>> {
    return {
      zipCode: [isRequired(), isShortZipCode()],
      state: isRequired(),
      utilityId: isRequired(),
      commodity: isRequired(),
      annualUsage: isRequired(),
      customerType: isRequired(),
      unit: isRequired(),
      term: isRequired(),
    };
  }
}

export default QuotesStore;
