import {
  CommercialSessionInterface,
  CommercialUtilityAccountInformation,
} from 'services/retail/commercial/types';
import { FormFilters, FormValidators } from 'utils/form/types';
import { action, observable } from 'mobx';
import { isAccountNumber, isFullZipCode, isRequired, maxStringLength } from 'utils/form/validators';
import { filterValue, filterValues, validateValue, validateValues } from 'utils/form';
import { BillSamples, State } from 'services/delivery/types';
import { stringRandom } from 'utils/common/string';
import { isAccountNumberOnType, isFullZipCodeOnType } from 'utils/form/asserts';
import { AnnualUsage } from 'services/orders/types';
import { trim } from 'utils/form/filters';

const initialForm: CommercialUtilityAccountInformation = {
  address: '',
  extendedAddress: '',
  city: '',
  county: '',
  state: null,
  zipCode: '',
  accountNumber: '',
  annualUsage: '',
};

type Errors = Partial<CommercialUtilityAccountInformation>;
type Values = string & number & State;
type Validators = FormValidators<CommercialUtilityAccountInformation>;

class CommercialUtilityStore {
  data = observable({
    form: initialForm,
    errors: {},
    isBillingAddress: false,
  });
  storeId: string;

  constructor(
    form: CommercialUtilityAccountInformation,
    private readonly commercialSession: CommercialSessionInterface
  ) {
    this.storeId = stringRandom();
    this.form = form;
  }

  static createFrom = (
    partial: Partial<CommercialUtilityAccountInformation>,
    commercialSession: CommercialSessionInterface
  ): CommercialUtilityStore => {
    return new CommercialUtilityStore({ ...initialForm, ...partial }, commercialSession);
  };

  onChange = (key: keyof CommercialUtilityAccountInformation) =>
    action((value: Values) => {
      if (
        (key === 'zipCode' && !isFullZipCodeOnType(value)) ||
        (key === 'accountNumber' && !isAccountNumberOnType(value))
      ) {
        return;
      }
      this.data.form[key] = value;
      this.validateProp(key);
    });

  validateProp = action((prop: keyof CommercialUtilityAccountInformation) => {
    const [, error] = validateValue<CommercialUtilityAccountInformation>(
      this.validators[prop],
      this.filterProp(prop)
    );
    this.data.errors[prop] = String(error);
  });

  get isValid(): boolean {
    const [isValid, errors] = validateValues<Errors>(this.validators, this.filterForm());
    this.data.errors = errors;

    return isValid;
  }

  get form(): CommercialUtilityAccountInformation {
    return this.data.form;
  }

  set form(form: CommercialUtilityAccountInformation) {
    this.data.form = form;
  }

  set isBillingAddress(value: boolean) {
    this.data.isBillingAddress = value;
  }

  get isBillingAddress(): boolean {
    return this.data.isBillingAddress;
  }

  get errors(): Errors {
    return this.data.errors;
  }

  get annualUsage(): AnnualUsage {
    return this.commercialSession.getAnnualUsage();
  }

  get billSamples(): BillSamples {
    return this.commercialSession.getBillSamples();
  }

  public filterForm = (): CommercialUtilityAccountInformation => {
    return filterValues<CommercialUtilityAccountInformation>(this.filters, this.form);
  };

  protected filterProp = (prop: string): any => {
    return this.form[prop] ? filterValue(this.filters[prop], this.form[prop]) : this.form[prop];
  };

  private get validators(): Validators {
    return {
      address: [isRequired(), maxStringLength(90)],
      city: [isRequired(), maxStringLength(60)],
      county: [maxStringLength(60)],
      zipCode: [isRequired(), isFullZipCode()],
      accountNumber: [isRequired(), isAccountNumber()],
      state: isRequired(),
      annualUsage: isRequired(),
      extendedAddress: maxStringLength(60),
    };
  }

  private get filters(): FormFilters {
    return {
      address: trim,
      city: trim,
      county: trim,
      extendedAddress: trim,
    };
  }
}

export default CommercialUtilityStore;
