import { action, observable } from 'mobx';
import { GetBestPlansRequest, GetPlansRequest, Plan, QuotesServiceInterface, } from 'services/quotes/types';
import { CommercialSessionInterface } from 'services/retail/commercial/types';
import { ResidentialSessionInterface } from 'services/retail/residential/types';
import {
  bestPlansStateToRequest,
  calcMinEffectiveDate,
  humanFormatEffectiveDate,
  PlansSorting,
  plansStateToRequest,
  PlansTableFilter,
} from 'utils/retail/plans';
import { AnnualUsageUnit, CustomerType } from 'services/orders/types';
import PlansFilterStore from 'stores/retail/PlansFilterStore';
import { PLANS_DEFAULT_SORT, PLANS_PAGE_SIZE } from 'config/quotes';
import { Commodity, State } from 'services/delivery/types';
import { getBillSamples } from 'utils/retail/plansFilter';
import {
  EVENT_TRIGGER_PARAM,
  triggerRetailChangeStartDateEvent,
  triggerRetailSelectPageEvent,
  triggerRetailSelectTermEvent,
  triggerRetailSortByEvent,
  triggerRetailViewPlansEvent,
} from 'config/seo/events';
import { DEFAULT_STATE } from 'config/retail';
import { getStateByShort } from 'utils/data';

class PlansStore {
  plans = observable({
    data: [],
    isLoading: true,
    noPlans: false,
  });

  bestPlans = observable({
    data: [],
    isLoading: true,
  });

  sorting: PlansSorting = observable({
    sort: PLANS_DEFAULT_SORT,
  });

  pagination = observable({
    total: 0,
    pageSize: PLANS_PAGE_SIZE,
    page: 1,
  });

  filter: PlansTableFilter = observable({
    effectiveDate: '',
    term: [],
  });

  tos = observable({
    plan: null,
  });

  lastSuccessfulPlansRequest: GetPlansRequest = null;

  public readonly minEffectiveDate: Date;

  constructor(
    public readonly plansFilterStore: PlansFilterStore,
    private readonly quoteService: QuotesServiceInterface,
    private readonly commercialSession: CommercialSessionInterface,
    private readonly residentialSession: ResidentialSessionInterface
  ) {
    this.minEffectiveDate = calcMinEffectiveDate();
    this.filter.effectiveDate = humanFormatEffectiveDate(this.minEffectiveDate);
  }

  get form() {
    return this.plansFilterStore.form;
  }

  get isResidential(): boolean {
    return this.form.customerType === CustomerType.RESIDENTIAL;
  }

  get session() {
    return this.isResidential ? this.residentialSession : this.commercialSession;
  }

  get tosPlan() {
    return this.tos.plan;
  }

  isPlanWithTOS = (plan: Plan): boolean => {
    return !!plan.documents?.tos;
  };

  setTOSPlan = (plan: Plan) => {
    this.tos.plan = plan;
  };

  unsetTOSPlan = () => {
    this.tos.plan = null;
  };

  startOrderSession = action(async (plan: Plan, backUrl: string, esignature = '') => {
    const session = this.session;
    session.setSelectedPlan(plan);
    session.setAnnualUsage({
      amount: Number(this.plansFilterStore.getAnnualUsage(false)),
      unit:
        this.plansFilterStore.form.commodity === Commodity.ELECTRICITY
          ? AnnualUsageUnit.KWH
          : AnnualUsageUnit.THERM,
    });
    session.setBackUrl(backUrl);
    session.setEffectiveDate(this.filter.effectiveDate);
    session.setBillSamples(
      getBillSamples(this.form.utility, this.form.commodity, this.form.customerType)
    );

    let state = this.plansFilterStore.getDefinedState();
    if (!state) {
      state = await this.plansFilterStore.getDefaultState();
    }
    const utilityInformation = { state, zipCode: this.form.zipCode };

    session.setMailingBillingInformation({ ...this.form, state });
    if (this.isResidential) {
      this.residentialSession.setUtilityAccountInformation(utilityInformation);
      this.residentialSession.unsetEsignature();
      if (esignature) {
        this.residentialSession.setEsignature(esignature);
      }
    } else {
      this.commercialSession.setUtilityAccountInformation([utilityInformation]);
    }
  });

  searchPlans = action(async (trigger = EVENT_TRIGGER_PARAM.userInteraction): Promise<boolean> => {
    this.plans.isLoading = true;
    try {
      let request = this.createPlansRequest();
      let response = await this.quoteService.getPlans(request);
      this.plans.noPlans = Number(response.total) === 0;
      triggerRetailViewPlansEvent(this.createViewPlansEventParams(), trigger);

      const { plans, total } = response;
      this.lastSuccessfulPlansRequest = request;
      this.plans.data = plans;
      this.pagination.total = Number(total);
      return this.plans.noPlans;
    } catch (e) {
      this.plans.noPlans = true;
    } finally {
      this.plans.isLoading = false;
    }
    return true;
  });

  searchBestPlans = action(async (trigger = EVENT_TRIGGER_PARAM.userInteraction): Promise<void> => {
    this.bestPlans.isLoading = true;
    try {
      const { bestPlans } = await this.quoteService.getBestPlans(
        await this.createBestPlansRequest()
      );
      if (bestPlans.length) {
        this.bestPlans.data = bestPlans;
      } else if (trigger === EVENT_TRIGGER_PARAM.pageLoad) {
        const { bestPlans } = await this.quoteService.getBestPlans(
          this.createDefaultRequest<GetBestPlansRequest>(this.createBestPlansRequest())
        );
        this.bestPlans.data = bestPlans;
      }
    } catch (e) {
      // this.plans.noPlans = true;
    } finally {
      this.bestPlans.isLoading = false;
    }
  });

  onSort = action((sort: string): void => {
    this.pagination.page = 1;
    this.sorting.sort = sort || PLANS_DEFAULT_SORT;
    triggerRetailSortByEvent(sort);
  });

  onPage = action((page: number): void => {
    this.pagination.page = page;
    triggerRetailSelectPageEvent(page);
  });

  onEffectiveDate = action((effectiveDate: string) => {
    this.filter.effectiveDate = effectiveDate;
    triggerRetailChangeStartDateEvent(effectiveDate);
  });

  onTerm = action((term: number[]) => {
    this.filter.term = term;
    triggerRetailSelectTermEvent(term.join(','));
  });

  paginationCount = action((): number => {
    return Math.ceil(this.pagination.total / this.pagination.pageSize);
  });

  getLastSuccessfulState = (): State => {
    return getStateByShort(
      this.lastSuccessfulPlansRequest ? this.lastSuccessfulPlansRequest.state : DEFAULT_STATE
    );
  };

  getLastSuccessfulCommodity = (): string => {
    return this.lastSuccessfulPlansRequest
      ? this.lastSuccessfulPlansRequest.commodity
      : this.plansFilterStore.form.commodity;
  };

  private createDefaultRequest = <T>(request: T): T => {
    // @ts-ignore
    const { classificationId, utilityId, rest } = request;
    return { ...rest, state: DEFAULT_STATE } as T;
  };

  private createPlansRequest = (): GetPlansRequest => {
    const request = {
      ...this.plansFilterStore.form,
      ...this.sorting,
      ...this.pagination,
      ...this.filter,
      state: this.plansFilterStore.getDefinedState(),
      annualUsage: this.plansFilterStore.getAnnualUsage(false),
      unit: this.plansFilterStore.getUnit().toUpperCase() as AnnualUsageUnit,
    }
    if (request.commodity === Commodity.GAS) {
      delete request.rateClass;
    }
    return plansStateToRequest(request);
  };

  private createBestPlansRequest = (): GetBestPlansRequest => {
    const request = {
      ...this.plansFilterStore.form,
      ...this.filter,
      state: this.plansFilterStore.getDefinedState(),
      annualUsage: this.plansFilterStore.getAnnualUsage(false),
      unit: this.plansFilterStore.getUnit().toUpperCase() as AnnualUsageUnit,
    };
    if (request.commodity === Commodity.GAS) {
      delete request.rateClass;
    }
    return bestPlansStateToRequest(request);
  };

  createViewPlansEventParams = () => {
    const params = {
      ...this.plansFilterStore.form,
      ...this.sorting,
      ...this.pagination,
      ...this.filter,
    } as Record<string, any>;
    return {
      ...params,
      zone: params.zone
        ? params.zone
        : this.plansFilterStore.lists.zone.find((zone) => zone.zoneId === params.zoneId) || null,
    };
  };
}

export default PlansStore;
