import { action, observable } from 'mobx';
import { fileTypes, maxFileSize } from 'utils/form/validators';
import { HttpStatus } from 'services/http/types';
import { validateValue } from 'utils/form';
import { stringRandom } from 'utils/common/string';
import { extractId } from 'utils/services';
import { UploadedBill } from 'services/retail/commercial/types';
import { MAX_UPLOADED_BILLS } from 'config/files';
import { FilesServiceInterface } from 'services/file/types';

interface BillsSession {
  setUploadedBills(uploadedBills: UploadedBill[]): void;
  getUploadedBills(): UploadedBill[];
}

class UploadBillStore {
  data = observable({
    uploadedFiles: [],
    errorMessage: '',
    serverErrorMessage: '',
  });

  constructor(
    private readonly billSession: BillsSession,
    private readonly fileService: FilesServiceInterface
  ) {}

  get showDropZone(): boolean {
    return this.data.uploadedFiles.length < MAX_UPLOADED_BILLS;
  }

  restoreFromSession = action(() => {
    this.data.uploadedFiles = this.billSession.getUploadedBills() || [];
  });

  get uploadedFiles(): UploadedBill[] {
    return this.data.uploadedFiles;
  }

  get errorMessage(): string {
    return this.data.errorMessage;
  }

  get serverErrorMessage(): string {
    return this.data.serverErrorMessage;
  }

  setErrorMessage = action((errorMessage: string) => {
    this.data.errorMessage = errorMessage;
  });

  setServerErrorMessage = action((errorMessage: string) => {
    this.data.serverErrorMessage = errorMessage;
  });

  clearServerErrorMessage = action(() => {
    this.data.serverErrorMessage = '';
  });

  addFile = action(async (file: File): Promise<void> => {
    if (this.data.uploadedFiles.length + 1 > MAX_UPLOADED_BILLS) {
      return;
    }
    const [isValid, errorMessage] = validateValue<{ file: UploadedBill }>(this.validators, file);
    this.data.errorMessage = String(errorMessage);
    if (!isValid) {
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    const uploadedFile: UploadedBill = {
      id: '',
      key: `${Date.now()}${stringRandom()}`,
      name: file.name,
      type: file.type,
      size: file.size,
      inProgress: true,
    };
    this.data.uploadedFiles = [...this.data.uploadedFiles, uploadedFile];
    try {
      const response = await this.fileService.createFileUpload(formData);
      if (response.status === HttpStatus.CREATED && response.headers.location) {
        const item = this.data.uploadedFiles.find(({ key }) => key === uploadedFile.key);
        item.id = extractId(response.headers.location);
        item.inProgress = false;
        this.storeSession();
      } else {
        this.onServerError(uploadedFile);
        return;
      }
    } catch (e) {
      this.onServerError(uploadedFile);
    }
  });

  removeFile = action((file: UploadedBill): void => {
    this.data.uploadedFiles = this.data.uploadedFiles.filter(({ key }) => key !== file.key);
    this.storeSession();
  });

  private onServerError = action((uploadedFile: UploadedBill) => {
    this.removeFile(uploadedFile);
    this.setServerErrorMessage('Something wrong on server side');
  });

  private storeSession = () => {
    this.billSession.setUploadedBills(this.data.uploadedFiles);
  };

  protected get validators() {
    return [
      maxFileSize(5242880), // 5MB
      fileTypes(['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'application/pdf']),
    ];
  }
}

export default UploadBillStore;
