import { CustomerType } from 'services/orders/types';
import { ENV } from 'evcharging/app';
import { Plan } from "services/quotes/types";

export const ANNUAL_USAGE_DEBOUNCE_DELAY_MS = 1000;
export const DEFAULT_ELECTRICITY_ANNUAL_USAGE = {
  [CustomerType.RESIDENTIAL]: 10000,
  [CustomerType.COMMERCIAL]: 70000,
};
export const DEFAULT_GAS_ANNUAL_USAGE = {
  [CustomerType.RESIDENTIAL]: 1000,
  [CustomerType.COMMERCIAL]: 7000,
};

const commonTime = [
  {
    '8-11': [20, 100],
    '12-15': [100, 200],
    '16-19': [200, 500],
    '20-23': [500, 700],
  },
  {
    '12-15': [10, 20],
    '16-19': [20, 60],
    '20-23': [60, 70],
  },
  {
    '12-15': [5, 10],
    '16-19': [10, 15],
    '20-23': [15, 20],
  },
];

export const DEFAULT_STATE = 'MD';
export const RETAIL_CONTRACT_COUNTER = {
  cookieKey: 'retailContractCounter',
  cookieOptions: { maxAge: 24 * 3600 },
  messageDelay: 3000,
  schedule: {
    IL: {
      '0-7': [3, 6],
      ...commonTime[0],
    },
    PA: {
      '0-7': [7, 10],
      ...commonTime[0],
    },
    OH: {
      '0-7': [11, 14],
      ...commonTime[0],
    },
    NJ: {
      '0-7': [15, 18],
      ...commonTime[0],
    },
    MD: {
      '0-7': [19, 25],
      ...commonTime[0],
    },
    MA: {
      '0-7': [0, 0],
      '8-11': [5, 10],
      ...commonTime[1],
    },
    DE: {
      '0-7': [0, 0],
      '8-11': [2, 4],
      ...commonTime[2],
    },
    RI: {
      '0-7': [0, 0],
      '8-11': [5, 7],
      ...commonTime[2],
    },
    ME: {
      '0-7': [0, 0],
      '8-11': [8, 11],
      ...commonTime[2],
    },
    NH: {
      '0-7': [0, 0],
      '8-11': [12, 14],
      ...commonTime[2],
    },
    NY: {
      '0-7': [0, 0],
      '8-11': [15, 18],
      ...commonTime[2],
    },
  },
};

export const SCAN_BILL_ABSOLUTE_URL = () =>
  ENV.IS_PRODUCTION ? 'https://scanbill.energy5.com/' : 'https://dev.scanbill.energy5.com/';

export const RETAIL_LEAD_FORM_VALUES_KEY = 'RETAIL_LEAD_FORM_VALUES';

const planShortDetails = [
  '05a62870-6e13-4067-8288-1d0b331933f5' // Constellation
]

export const isPlanShortDetails = (plan: Plan) => planShortDetails.includes(plan.supplier.supplierId);
