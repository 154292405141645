import { action, observable } from 'mobx';
import { FormFilters, FormValidators } from 'utils/form/types';
import { isEmail, isPhone, isRequired, maxStringLength, onlyLetters } from 'utils/form/validators';
import { filterValue, filterValues, validateValue, validateValues } from 'utils/form';
import { trim } from 'utils/form/filters';
import {
  ResidentialCustomerInformation,
  ResidentialSessionInterface,
} from 'services/retail/residential/types';
import { Plan } from 'services/quotes/types';
import { isMajorEnergy } from 'config/suppliers';

const initialForm: ResidentialCustomerInformation = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  middleName: '',
  relationship: '',
};

type Errors = Partial<ResidentialCustomerInformation>;
type Values = string;
type Validators = FormValidators<ResidentialCustomerInformation>;

class ResidentialCustomerInformationStore {
  data = observable({
    form: initialForm,
    errors: {},
  });

  constructor(private readonly residentialSession: ResidentialSessionInterface) {}

  restoreFromSession = action(() => {
    const customerInformation = this.residentialSession.getCustomerInformation();
    this.data.form = customerInformation ? customerInformation : initialForm;
  });

  getSelectedPlan = (): Plan | undefined => {
    return this.residentialSession.getSelectedPlan();
  };

  onChange = (key: keyof ResidentialCustomerInformation) =>
    action((value: Values) => {
      this.data.form[key] = value;
      this.validateProp(key);
    });

  onSubmit = (onSuccess: { (): void }): void => {
    if (this.isValid) {
      this.residentialSession.setCustomerInformation(this.filterForm());
      onSuccess();
    }
  };

  get form(): ResidentialCustomerInformation {
    return this.data.form;
  }

  get errors(): Errors {
    return this.data.errors;
  }

  get hasErrors(): boolean {
    const [isValid] = validateValues<Errors>(this.validators, this.filterForm());

    return !isValid;
  }

  get isValid(): boolean {
    const [isValid, errors] = validateValues<Errors>(this.validators, this.filterForm());
    this.data.errors = errors;

    return isValid;
  }

  validateProp = action((prop: keyof ResidentialCustomerInformation) => {
    const [, error] = validateValue<ResidentialCustomerInformation>(
      this.validators[prop],
      this.filterProp(prop)
    );
    this.data.errors[prop] = String(error);
  });

  protected filterForm = (): ResidentialCustomerInformation => {
    return filterValues<ResidentialCustomerInformation>(this.filters, this.form);
  };

  protected filterProp = (prop: string): any => {
    return this.form[prop] ? filterValue(this.filters[prop], this.form[prop]) : this.form[prop];
  };

  private get validators(): Validators {
    return {
      firstName: [isRequired(), onlyLetters(), maxStringLength(50)],
      lastName: [isRequired(), onlyLetters(), maxStringLength(50)],
      phone: [isRequired(), isPhone()],
      email: [isRequired(), isEmail()],
      middleName: [onlyLetters(), maxStringLength(50)],
      relationship: isMajorEnergy(this.getSelectedPlan().supplier)
        ? [isRequired(), onlyLetters(), maxStringLength(30)]
        : [],
    };
  }

  private get filters(): FormFilters {
    return {
      firstName: trim,
      lastName: trim,
      middleName: trim,
      relationship: trim,
    };
  }
}

export default ResidentialCustomerInformationStore;
