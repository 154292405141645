import {
  ResidentialMailingBillingInformation,
  ResidentialSessionInterface,
} from 'services/retail/residential/types';
import { FormFilters, FormValidators } from 'utils/form/types';
import { action, observable } from 'mobx';
import { isFullZipCode, isRequired, maxStringLength } from 'utils/form/validators';
import { filterValue, filterValues, validateValue, validateValues } from 'utils/form';
import { State } from 'services/delivery/types';
import { isFullZipCodeOnType } from 'utils/form/asserts';
import { trim } from 'utils/form/filters';

const initialForm: ResidentialMailingBillingInformation = {
  address: '',
  extendedAddress: '',
  city: '',
  county: '',
  state: null,
  zipCode: '',
};

type Errors = Partial<ResidentialMailingBillingInformation>;
type Values = string & number & State;
type Validators = FormValidators<ResidentialMailingBillingInformation>;

class ResidentialMailingBillingInformationStore {
  data = observable({
    form: initialForm,
    errors: {},
  });

  restoreFromSession = action((residentialSession: ResidentialSessionInterface) => {
    const mailingBillingInformation = residentialSession.getMailingBillingInformation();
    this.data.form = mailingBillingInformation ? mailingBillingInformation : initialForm;
  });

  onChange = (key: keyof ResidentialMailingBillingInformation) =>
    action((value: Values) => {
      if (key === 'zipCode' && !isFullZipCodeOnType(value)) {
        return;
      }
      this.data.form[key] = value;
      this.validateProp(key);
    });

  get hasErrors(): boolean {
    const [isValid] = validateValues<Errors>(this.validators, this.filterForm());
    return !isValid;
  }

  get isValid(): boolean {
    const [isValid, errors] = validateValues<Errors>(this.validators, this.filterForm());
    this.data.errors = errors;

    return isValid;
  }

  get form(): ResidentialMailingBillingInformation {
    return this.data.form;
  }

  get errors(): Errors {
    return this.data.errors;
  }

  validateProp = action((prop: keyof ResidentialMailingBillingInformation) => {
    const [, error] = validateValue<ResidentialMailingBillingInformation>(
      this.validators[prop],
      this.filterProp(prop)
    );
    this.data.errors[prop] = String(error);
  });

  filterForm = (): ResidentialMailingBillingInformation => {
    return filterValues<ResidentialMailingBillingInformation>(this.filters, this.form);
  };

  clearForm = () => {
    const { address, extendedAddress, city, county } = initialForm;
    this.data.form = { ...this.data.form, address, extendedAddress, city, county };
  };

  protected filterProp = (prop: string): any => {
    return this.form[prop] ? filterValue(this.filters[prop], this.form[prop]) : this.form[prop];
  };

  private get validators(): Validators {
    return {
      address: [isRequired(), maxStringLength(90)],
      city: [isRequired(), maxStringLength(60)],
      county: [maxStringLength(60)],
      state: isRequired(),
      zipCode: [isFullZipCode()],
      extendedAddress: maxStringLength(60),
    };
  }

  private get filters(): FormFilters {
    return {
      address: trim,
      city: trim,
      county: trim,
      extendedAddress: trim,
    };
  }
}

export default ResidentialMailingBillingInformationStore;
