import { action, observable } from 'mobx';
import { FormValidators } from 'utils/form/types';
import { validateValues } from 'utils/form';
import { HttpMethod } from 'services/http/types';
import { getAbsoluteUrl } from 'utils/services';
import { isShortZipCodeOnType } from 'utils/form/asserts';

abstract class EmailBaseFormStore<Form> {
  data = observable({
    form: this.getInitialForm(),
    errors: {},
    isConfirm: true,
    isLoading: false,
    errorMessage: '',
  });

  protected abstract getInitialForm(): Form;

  protected abstract get validators(): FormValidators<Form>;

  protected abstract get endpoint(): string;

  get isLoading(): boolean {
    return this.data.isLoading;
  }

  get isConfirm(): boolean {
    return this.data.isConfirm;
  }

  get getForm(): Form {
    return this.data.form;
  }

  get getErrors(): Partial<Form> {
    return this.data.errors;
  }

  get getErrorMessage(): string {
    return this.data.errorMessage;
  }

  showMessage = action((message: string) => {
    this.data.errorMessage = message;
  });

  closeError = action(() => {
    this.data.errorMessage = '';
  });

  clearForm = action(() => {
    this.data.form = this.getInitialForm();
    this.data.errors = {};
  });

  disableConfirm = action(() => {
    this.data.isConfirm = true;
  });

  changeZipCode = () =>
    action((value: string) => {
      if (value.length > 5 || !isShortZipCodeOnType(value)) {
        return;
      }
      this.data.form['zipCode'] = value;
    });

  onChange = (key: string) =>
    action((value: string | number | Date | null | boolean) => {
      this.data.form[key] = value;
    });

  get isValid(): boolean {
    const [isValid, errors] = validateValues<Form>(this.validators, this.data.form);
    this.data.errors = errors;

    return isValid;
  }

  onSubmit = action(async (more: Record<string, any> = {}): Promise<void> => {
    if (!this.isValid) {
      return;
    }

    this.data.isLoading = true;
    const formData = new FormData();
    this.beforeSubmit(formData);
    Object.entries(this.data.form)
      .filter(([, value]) => !!value)
      .forEach(([key, value]) => formData.append(key, value));

    Object.entries(more)
      .filter(([, value]) => !!value)
      .forEach(([key, value]) => formData.append(key, value));

    try {
      const res = await fetch(getAbsoluteUrl(this.endpoint), {
        method: HttpMethod.POST,
        body: formData,
      });

      if (res.ok) {
        this.clearForm();
        this.data.isLoading = false;
        this.data.isConfirm = false;
        this.onSuccessSubmit();
        setTimeout(() => {
          this.data.isConfirm = true;
        }, 5000);
      } else {
        this.showMessage('Internal Server Error');
      }
    } catch (e) {
      console.error(e);
      this.showMessage('Internal Server Error');
    } finally {
      this.data.isLoading = false;
    }
  });

  protected beforeSubmit = (formData: FormData) => undefined;

  protected onSuccessSubmit = () => undefined;
}
export default EmailBaseFormStore;
