import { FormValidators } from 'utils/form/types';
import { isEmail, isPhone, isRequired } from 'utils/form/validators';
import EmailBaseFormStore from 'stores/common/EmailBaseFormStore';
import { EMAIL_SEND_INQUIRE_ENDPOINT } from 'config/email/endpoints';
import { triggerRetailSubmitLeadEvent } from 'config/seo/events';

export interface InquireForm {
  fullName: string;
  email: string;
  phone: string;
  message: string;
}

class InquireFormStore extends EmailBaseFormStore<InquireForm> {
  protected get endpoint(): string {
    return EMAIL_SEND_INQUIRE_ENDPOINT;
  }

  protected getInitialForm(): InquireForm {
    return {
      fullName: '',
      email: '',
      phone: '',
      message: '',
    };
  }

  protected get validators(): FormValidators<InquireForm> {
    return {
      fullName: isRequired(),
      email: [isRequired(), isEmail()],
      // phone: this.data.form.phone.length ? isPhone() : [],
      phone: [isRequired(), isPhone()],
    };
  }

  onSuccessSubmit = () => {
    triggerRetailSubmitLeadEvent();
  };
}

export default InquireFormStore;
