import {
  CommercialMailingBillingInformation,
  CommercialSessionInterface,
} from 'services/retail/commercial/types';
import { FormFilters, FormValidators } from 'utils/form/types';
import { action, observable } from 'mobx';
import { isFullZipCode, isRequired, maxStringLength } from 'utils/form/validators';
import { filterValue, filterValues, validateValue, validateValues } from 'utils/form';
import { State } from 'services/delivery/types';
import { isFullZipCodeOnType } from 'utils/form/asserts';
import { trim } from 'utils/form/filters';
import { triggerRetailAddMailingInfoEvent } from 'config/seo/events';

const initialForm: CommercialMailingBillingInformation = {
  address: '',
  extendedAddress: '',
  city: '',
  county: '',
  state: null,
  zipCode: '',
};

type Errors = Partial<CommercialMailingBillingInformation>;
type Values = string & number & State;
type Validators = FormValidators<CommercialMailingBillingInformation>;

class CommercialMailingBillingAddressStore {
  data = observable({
    form: initialForm,
    errors: {},
  });

  constructor(private readonly commercialSession: CommercialSessionInterface) {}

  restoreFromSession = action(() => {
    const mailingBillingInformation = this.commercialSession.getMailingBillingInformation();
    this.data.form = mailingBillingInformation ? mailingBillingInformation : initialForm;
  });

  onSubmit = (onSuccess: { (): void }): void => {
    if (this.isValid) {
      this.commercialSession.setMailingBillingInformation(this.filterForm());
      triggerRetailAddMailingInfoEvent();
      onSuccess();
    }
  };

  onChange = (key: keyof CommercialMailingBillingInformation) =>
    action((value: Values) => {
      if (key === 'zipCode' && !isFullZipCodeOnType(value)) {
        return;
      }
      this.data.form[key] = value;
      this.validateProp(key);
    });

  validateProp = action((prop: keyof CommercialMailingBillingInformation) => {
    const [, error] = validateValue<CommercialMailingBillingInformation>(
      this.validators[prop],
      this.filterProp(prop)
    );
    this.data.errors[prop] = String(error);
  });

  get isValid(): boolean {
    const [isValid, errors] = validateValues<Errors>(this.validators, this.filterForm());
    this.data.errors = errors;

    return isValid;
  }

  get form(): CommercialMailingBillingInformation {
    return this.data.form;
  }

  set form(form: CommercialMailingBillingInformation) {
    this.data.form = form;
  }

  get errors(): Errors {
    return this.data.errors;
  }

  public filterForm = (): CommercialMailingBillingInformation => {
    return filterValues<CommercialMailingBillingInformation>(this.filters, this.form);
  };

  protected filterProp = (prop: string): any => {
    return this.form[prop] ? filterValue(this.filters[prop], this.form[prop]) : this.form[prop];
  };

  private get validators(): Validators {
    return {
      address: [isRequired(), maxStringLength(90)],
      city: [isRequired(), maxStringLength(60)],
      county: [maxStringLength(60)],
      zipCode: [isRequired(), isFullZipCode()],
      state: isRequired(),
      extendedAddress: maxStringLength(60),
    };
  }

  private get filters(): FormFilters {
    return {
      address: trim,
      city: trim,
      county: trim,
      extendedAddress: trim,
    };
  }
}

export default CommercialMailingBillingAddressStore;
