import { Plan, Supplier } from "services/quotes/types";
import { greenEnergyImage, greenEnergyWindImage } from "assets/images/retail";

const majorEnergyId = "df32052f-ce9d-4bfb-8e49-9cde415a1d2e";

export const getLogoBySupplier = (supplier: Supplier) => supplier.logoUrl;

export const getGreenEnergyImage = (plan: Plan) => {
  if (plan.energySource === 'WIND') {
    return greenEnergyWindImage;
  }
  if (plan.greenEnergy > 0) {
    return greenEnergyImage;
  }
}

export const isMajorEnergy = (supplier: Supplier) => supplier.supplierId === majorEnergyId;

const supplierLogos = {
  "05a62870-6e13-4067-8288-1d0b331933f5": {
    width: 150,
    height: 36,
    src: "https://admin.energy5.com/assets/6f441c83-ebb7-479f-b774-e8df06c51c48"
  },
  "74195270-8836-4b9e-95b6-51eac66f491d": {
    width: 69,
    height: 41,
    src: "https://admin.energy5.com/assets/2bcf05ac-bbe5-423b-95ea-bf4cf56abd8d"
  },
  "df32052f-ce9d-4bfb-8e49-9cde415a1d2e": {
    width: 160,
    height: 22,
    src: "https://admin.energy5.com/assets/7c306ca1-acee-45ab-bbdf-655072f4d987"
  },
  "a837a4ca-3154-4136-83d5-7efb46153394": {
    width: 150,
    height: 42,
    src: "https://admin.energy5.com/assets/02f7219c-858a-4742-9fc6-1169504ae121"
  },
  "4d87e166-2540-4950-91bf-f3369f870c7f": {
    width: 90,
    height: 40,
    src: "https://admin.energy5.com/assets/7eb0ed09-1493-4870-ae62-967941d332b0"
  },
  "5179a83c-d6c1-449f-9faf-ca99db62c2bb": {
    width: 160,
    height: 54,
    src: "https://admin.energy5.com/assets/43a3fd5b-b1dd-4ddf-9668-38ddbf4cd047"
  },
  "c2fd0596-9299-4001-9817-2782fc8d7cf6": {
    width: 160,
    height: 25,
    src: "https://admin.energy5.com/assets/862f9335-44fe-4c48-98eb-52ced4f22d96"
  },
  "27ff913e-9335-42c4-afef-cafb3550c09d": {
    width: 100,
    height: 40,
    src: "https://admin.energy5.com/assets/6bd8f9f9-3ffd-409c-9529-f93f296854ea"
  }
};

export const getLogoBySupplier2 = (supplier: Supplier) => supplierLogos[supplier.supplierId] || {
  width: 100,
  height: 70,
  src: supplier.logoUrl
};

