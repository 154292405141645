const ZIP_REGEX_SHORT = /^(\d{0,5})$/; //12345 - Enter Value Quote Filter
const ZIP_REGEX_FULL = /^(\d{1,5})?(\d{5}-\d{0,4})?$/; //12345 OR 12345-1234 - Enter Value Customer Registration
const ACCOUNT_NUMBER = /^(\d{0,22})$/; //1231231231 - Enter Value Customer Registration

export const isShortZipCodeOnType = (value: string): boolean => {
  return ZIP_REGEX_SHORT.test(value);
};
export const isFullZipCodeOnType = (value: string): boolean => {
  return ZIP_REGEX_FULL.test(value);
};
export const isAccountNumberOnType = (value: string): boolean => {
  return ACCOUNT_NUMBER.test(value);
};
