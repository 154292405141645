import { Plan } from 'services/quotes/types';
import { CommercialSessionInterface } from 'services/retail/commercial/types';
import { AnnualUsage } from 'services/orders/types';

class CommercialOrderStore {
  constructor(private readonly commercialSession: CommercialSessionInterface) {}

  isValidSession = (activeStep: number): boolean => {
    switch (activeStep) {
      case 1:
        return (
          this.hasSelectedPlan() &&
          this.hasUtilityAccountInformation() &&
          this.hasMailingBillingInformation()
        );
      case 2:
      case 3:
      case 4:
        return (
          this.hasSelectedPlan() &&
          this.hasCustomerInformation() &&
          this.hasUtilityAccountInformation() &&
          this.hasMailingBillingInformation()
        );
      default:
        return false;
    }
  };

  get annualUsage(): AnnualUsage {
    return this.commercialSession.getAnnualUsage();
  }

  hasSelectedPlan = (): boolean => {
    return !!this.commercialSession.getSelectedPlan();
  };

  hasCustomerInformation = (): boolean => {
    return !!this.commercialSession.getCustomerInformation();
  };

  hasUtilityAccountInformation = (): boolean => {
    return !!this.commercialSession.getUtilityAccountInformation();
  };

  hasMailingBillingInformation = (): boolean => {
    return !!this.commercialSession.getMailingBillingInformation();
  };

  getSelectedPlan = (): Plan => {
    return this.commercialSession.getSelectedPlan();
  };

  getBackUrl = (): string => {
    return this.commercialSession.getBackUrl();
  };
}

export default CommercialOrderStore;
