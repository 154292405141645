import { ResidentialSessionInterface } from 'services/retail/residential/types';
import { FieldListProps } from 'components/common/structures/FieldList/FieldList';
import {
  CreateResidentialOrderRequest,
  CustomerType,
  OrderLineItem,
  OrdersServiceInterface,
  ServiceType,
} from 'services/orders/types';
import { action, observable } from 'mobx';
import { HttpStatus } from 'services/http/types';
import { isMajorEnergy } from 'config/suppliers';
import { unitByAnnualUsage } from 'utils/data';
import { handleError } from 'utils/errors';

class ResidentialConfirmStore {
  order = observable({
    isLoading: false,
    errorMessage: '',
  });

  constructor(
    private readonly ordersService: OrdersServiceInterface,
    private readonly residentialSession: ResidentialSessionInterface
  ) {}

  get isLoading(): boolean {
    return this.order.isLoading;
  }

  get errorMessage(): string {
    return this.order.errorMessage;
  }

  clearErrorMessage = action(() => {
    this.order.errorMessage = '';
  });

  hasStartedSession = (): boolean => {
    return this.residentialSession.hasStarted();
  };

  makeInformationList = (): FieldListProps[] => {
    const result = [this.makeCustomerInformation(), this.makeUtilityAccountInformation()];
    if (this.residentialSession.hasMailingBillingInformation()) {
      result.push(this.makeMailingBillingInformation());
    }

    return result;
  };

  clearSession = (): void => {
    this.residentialSession.clear();
  };

  createOrder = action(async (onSuccess: () => void) => {
    const request = this.makeRequest();
    this.order.isLoading = true;
    try {
      await this.ordersService.createResidentialOrder(request);
      onSuccess();
    } catch (e) {
      handleError(e);
      const { status } = e?.response;
      this.order.errorMessage =
        status === HttpStatus.UNPROCESSABLE_ENTITY
          ? "confirmation.validation-error"
          : `confirmation.server-error`;
    } finally {
      this.order.isLoading = false;
    }
  });

  private makeRequest = (): CreateResidentialOrderRequest => {
    const { getCustomerInformation, getMailingBillingInformation, getUtilityAccountInformation } =
      this.residentialSession;
    const { firstName, middleName, relationship, lastName, email, phone, esignature } =
      getCustomerInformation();
    const { address, city, state, county, zipCode, extendedAddress } =
      this.residentialSession.hasMailingBillingInformation()
        ? getMailingBillingInformation()
        : getUtilityAccountInformation();

    return {
      serviceType: ServiceType.RETAIL_ENERGY,
      customerInfo: {
        type: CustomerType.RESIDENTIAL,
        firstName,
        lastName,
        middleName,
        esignature,
        relationship: relationship || null,
        emailAddress: email,
        phoneNumber: phone,
        mailingAddress: {
          address,
          city,
          county: county,
          state: state.state,
          zipCode,
          extendedAddress,
        },
      },
      orderLineItems: this.makeOrderLineItems(),
    };
  };

  private makeOrderLineItems = (): OrderLineItem[] => {
    const { getUtilityAccountInformation, getSelectedPlan, getAnnualUsage, getUploadedBills } =
      this.residentialSession;
    const { planId } = getSelectedPlan();
    const { address, city, state, county, zipCode, accountNumber, extendedAddress } =
      getUtilityAccountInformation();
    const annualUsage = getAnnualUsage();
    const uploadedBills = getUploadedBills() || [];

    return [
      {
        planId,
        utilityAccounts: [
          {
            accountNumber,
            serviceAddress: {
              address,
              city,
              county: county,
              extendedAddress,
              state: state.state,
              zipCode,
            },
            annualUsage,
          },
        ],
        fileIds: uploadedBills.map((file) => file.id),
      },
    ];
  };

  private makeCustomerInformation = () => {
    const { getCustomerInformation, getEffectiveDate, getSelectedPlan } = this.residentialSession;
    const { firstName, middleName, relationship, lastName, email, phone } =
      getCustomerInformation();
    const effectiveDate = getEffectiveDate();
    const isMajor = isMajorEnergy(getSelectedPlan().supplier);

    const relationshipItem = isMajor
      ? [{ title: 'retail.relationship-to-account', value: relationship }]
      : [];

    return {
      header: 'retail.customer-info',
      items: [
        { title: 'form.label.name', value: `${firstName} ${middleName} ${lastName}` },
        { title: 'form.label.phone', value: phone },
        { title: 'form.label.email', value: email },
        ...relationshipItem,
        { title: 'confirmation.contract-start-date', value: effectiveDate },
      ],
    };
  };

  private makeUtilityAccountInformation = () => {
    const { getUtilityAccountInformation, getUploadedBills, getAnnualUsage } =
      this.residentialSession;
    const { address, extendedAddress, city, state, county, zipCode, accountNumber } =
      getUtilityAccountInformation();
    const uploadedBills = getUploadedBills();
    const annualUsage = getAnnualUsage();
    const unit = unitByAnnualUsage(annualUsage, true);

    const result = {
      header: 'confirmation.utility-info',
      items: [
        {
          title: 'confirmation.service-address',
          value: `${address}, ${extendedAddress || ''} ${city}, ${county || ''} 
          ${state.state} ${zipCode}`,
        },
        { title: 'confirmation.account-number', value: accountNumber },
        {
          title: 'confirmation.annual-usage',
          value: `${annualUsage.amount.toLocaleString('en-US')} ${unit}`,
        },
      ],
    };

    if (uploadedBills.length) {
      // @ts-ignore
      result.items.push({ title: 'confirmation.uploaded-bills', value: uploadedBills.map(({ name }) => name) });
    }

    return result;
  };

  private makeMailingBillingInformation = () => {
    const { getMailingBillingInformation } = this.residentialSession;
    const { address, extendedAddress, city, state, county, zipCode } =
      getMailingBillingInformation();

    return {
      header: 'confirmation.mailing-billing',
      items: [
        {
          title: 'form.label.address',
          value: `${address}, ${extendedAddress || ''} ${city}, ${county || ''} 
          ${state.state} ${zipCode}`,
        },
      ],
    };
  };
}

export default ResidentialConfirmStore;
