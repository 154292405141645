import { FormValidators } from 'utils/form/types';
import { PlansQueryFilter } from 'services/quotes/types';
import { inList, isRequired, isShortZipCode } from 'utils/form/validators';
import { BillSamples, Commodity, Utility, ZipCodeResponse } from 'services/delivery/types';
import { CustomerType, CustomerTypeView } from 'services/orders/types';
import { validateValues } from 'utils/form';

const pathValidators = (): FormValidators<Partial<PlansQueryFilter>> => {
  return {
    zipCode: [isRequired(), isShortZipCode()],
    commodity: [
      isRequired(),
      inList([Commodity.GAS.toLowerCase(), Commodity.ELECTRICITY.toLowerCase()]),
    ],
    customerType: [isRequired(), inList([CustomerTypeView.home, CustomerTypeView.business])],
  };
};

export const isValidPath = (path: Record<string, string>): boolean => {
  return validateValues<Partial<PlansQueryFilter>>(pathValidators(), path)[0];
};

export const utilitiesByCustomerType = (
  stateUtilityList: Utility[],
  customerType: CustomerType
): Utility[] => {
  return stateUtilityList.filter((utility) => {
    return utility.commodities.some((item) => {
      return (
        item.commodity.toUpperCase() === Commodity.GAS ||
        (item.classifications &&
          item.classifications.some((classification) => {
            return (
              classification.customerTypes &&
              (classification.customerTypes.includes(customerType.toUpperCase()) ||
                classification.customerTypes.includes(customerType.toLowerCase()))
            );
          }))
      );
    });
  });
};

export const utilitiesByCommodity = (
  utilityList: Utility[],
  commodity: Commodity,
  customerType: CustomerType
) => {
  return utilityList.filter((utility) =>
    utility.commodities.some(
      (item) =>
        item.commodity.toLowerCase() === commodity.toLowerCase() &&
        (item.commodity.toUpperCase() === Commodity.GAS ||
          (item.classifications &&
            item.classifications.some((classification) => {
              return (
                classification.customerTypes &&
                (classification.customerTypes.includes(customerType.toUpperCase()) ||
                  classification.customerTypes.includes(customerType.toLowerCase()))
              );
            })))
    )
  );
};

export const highlightUtility = (
  zipCodeResponse: ZipCodeResponse,
  utilityList: Utility[],
  commodity: Commodity
) => {
  if (!zipCodeResponse) {
    return null;
  }
  const highlight = zipCodeResponse.defaultUtility ?? zipCodeResponse.utilities.find(
    (utility) => utility.commodity.toLowerCase() === commodity.toLowerCase()
  );
  return utilityList.find((utility) => utility.utilityId === highlight?.utilityId) || null;
};

export const changedUtilityAndCommodity = (
  utilityList: Utility[],
  commodity: Commodity,
  customerType: CustomerType
) => {
  const utilities = {};
  const commodityList = [Commodity.ELECTRICITY, Commodity.GAS];
  const anotherCommodity =
    commodity === Commodity.ELECTRICITY ? Commodity.GAS : Commodity.ELECTRICITY;

  utilities[Commodity.GAS] = utilityList.filter((utility) =>
    utility.commodities.some((item) => item.commodity.toLowerCase() === Commodity.GAS.toLowerCase())
  );
  if (!utilities[Commodity.GAS].length) {
    commodityList.splice(1, 1);
  }

  utilities[Commodity.ELECTRICITY] = utilityList.filter((utility) =>
    utility.commodities.some(
      (item) =>
        item.commodity.toLowerCase() === Commodity.ELECTRICITY.toLowerCase() &&
        item.classifications &&
        item.classifications.some((classification) => {
          return (
            classification.customerTypes &&
            (classification.customerTypes.includes(customerType.toUpperCase()) ||
              classification.customerTypes.includes(customerType.toLowerCase()))
          );
        })
    )
  );
  if (!utilities[Commodity.ELECTRICITY].length) {
    commodityList.splice(0, 1);
  }

  if (utilities[commodity].length) {
    return { commodityList, utilityList: utilities[commodity] };
  }

  if (utilities[anotherCommodity].length) {
    return { commodityList, anotherCommodity };
  }

  return { commodityList, utilityList: [] };
};

export const getBillSamples = (
  utility: Utility,
  commodity: Commodity,
  customerType: CustomerType
): BillSamples | Record<string, string> => {
  if (!utility) {
    return {};
  }
  for (const item of utility.commodities) {
    if (item.commodity.toLowerCase() === commodity.toLowerCase()) {
      if (!item.billSamples) {
        return {};
      }
      return item.billSamples.find((item) => item.customerType == customerType) || {};
    }
  }
  return {};
};
