import { FormValidators } from 'utils/form/types';
import { isEmail, isRequired } from 'utils/form/validators';
import EmailBaseFormStore from 'stores/common/EmailBaseFormStore';
import { EMAIL_STAY_UPDATE_ENDPOINT } from 'config/email/endpoints';
import { createRetailViewPlansParams, triggerRetailSubmitStayUpdateEvent } from 'config/seo/events';
import PlansStore from 'stores/retail/PlansStore';

interface Form {
  email: string;
}

class StayUpdateFormStore extends EmailBaseFormStore<Form> {
  constructor(private readonly plansStore: PlansStore) {
    super();
  }

  protected get endpoint(): string {
    return EMAIL_STAY_UPDATE_ENDPOINT;
  }

  protected getInitialForm(): Form {
    return {
      email: '',
    };
  }

  protected get validators(): FormValidators<Form> {
    return {
      email: [isRequired(), isEmail()],
    };
  }

  protected beforeSubmit = (formData: FormData) => {
    const filter = createRetailViewPlansParams(this.plansStore.createViewPlansEventParams());
    Object.entries(filter).forEach(([key, value]) => formData.append(key, value as string));
  };

  protected onSuccessSubmit = triggerRetailSubmitStayUpdateEvent;
}

export default StayUpdateFormStore;
