import { AnnualUsageUnit, CustomerType } from 'services/orders/types';

export interface State {
  stateName: string;
  state?: string;
  zones?: string[];
}

export enum Commodity {
  ELECTRICITY = 'ELECTRICITY',
  GAS = 'GAS',
}

interface HighlightUtility {
  utilityId: string;
  commodity: keyof typeof Commodity;
}

export interface ZipCodeResponse {
  zipCode: string;
  city: string;
  state: string;
  county: string;
  country: string;
  utilities?: HighlightUtility[];
  defaultUtility?: Utility;
}

export interface StateResponse {
  name: string;
  usps: string;
  deregulated: boolean;
}

export interface Classification {
  classificationId: string;
  description: string;
  name: string;
  customerTypes?: string[];
}

export interface Zone {
  zoneId: string;
  name: string;
}

export interface BillSamples {
  classificationUrl?: string;
  annualUsageUrl?: string;
  accountNumberUrl?: string;
}

interface CommodityItemBillSamples extends BillSamples {
  customerType: CustomerType;
}

interface CommodityItem {
  commodity: keyof typeof Commodity;
  classifications?: Classification[];
  unit: AnnualUsageUnit;
  zones?: Zone[];
  billSamples: CommodityItemBillSamples[];
}

export interface Utility {
  utilityId: string;
  name: string;
  commodities: CommodityItem[];
}

export interface DeliveryServiceInterface {
  getDeregulatedStates(query?: string): Promise<State[]>;

  getAllStates(query?: string): Promise<State[]>;

  getZipCode(zipCode: string): Promise<ZipCodeResponse>;

  getUtilityByState(state: string): Promise<Utility[]>;

  getUtilityByZipCode(zipCode: string): Promise<Utility[]>;

  getUtilityById(utilityId: string): Promise<Utility>;

  getStateByCode(code: string): Promise<State | void>;
}
