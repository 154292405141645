import { action, observable } from 'mobx';
import { FormFilters, FormValidators } from 'utils/form/types';
import { isEmail, isPhone, isRequired, maxStringLength, onlyLetters } from 'utils/form/validators';
import { filterValue, filterValues, validateValue, validateValues } from 'utils/form';
import { trim } from 'utils/form/filters';
import {
  CommercialCustomerInformation,
  CommercialSessionInterface,
} from 'services/retail/commercial/types';

const initialForm: CommercialCustomerInformation = {
  legalEntityName: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  middleName: '',
  dba: '',
  jobTitle: '',
};

type Errors = Partial<CommercialCustomerInformation>;
type Values = string;
type Validators = FormValidators<CommercialCustomerInformation>;

class CommercialCustomerInformationStore {
  data = observable({
    form: initialForm,
    errors: {},
  });

  constructor(private readonly commercialSession: CommercialSessionInterface) {}

  restoreFromSession = action(() => {
    const customerInformation = this.commercialSession.getCustomerInformation();
    this.data.form = customerInformation ? customerInformation : initialForm;
  });

  onChange = (key: keyof CommercialCustomerInformation) =>
    action((value: Values) => {
      this.data.form[key] = value;
      this.validateProp(key);
    });

  onSubmit = (onSuccess: { (): void }): void => {
    if (this.isValid) {
      this.commercialSession.setCustomerInformation(this.filterForm());
      onSuccess();
    }
  };

  validateProp = action((prop: keyof CommercialCustomerInformation) => {
    const [, error] = validateValue<CommercialCustomerInformation>(
      this.validators[prop],
      this.filterProp(prop)
    );
    this.data.errors[prop] = String(error);
  });

  get isValid(): boolean {
    const [isValid, errors] = validateValues<Errors>(this.validators, this.filterForm());
    this.data.errors = errors;

    return isValid;
  }

  get form(): CommercialCustomerInformation {
    return this.data.form;
  }

  get errors(): Errors {
    return this.data.errors;
  }

  protected filterForm = (): CommercialCustomerInformation => {
    return filterValues<CommercialCustomerInformation>(this.filters, this.form);
  };

  protected filterProp = (prop: string): any => {
    return this.form[prop] ? filterValue(this.filters[prop], this.form[prop]) : this.form[prop];
  };

  private get validators(): Validators {
    return {
      legalEntityName: [isRequired(), maxStringLength(255)],
      firstName: [isRequired(), onlyLetters(), maxStringLength(50)],
      lastName: [isRequired(), onlyLetters(), maxStringLength(50)],
      phone: [isRequired(), isPhone()],
      email: [isRequired(), isEmail()],
      middleName: [onlyLetters(), maxStringLength(50)],
      dba: maxStringLength(255),
      jobTitle: maxStringLength(50),
    };
  }

  private get filters(): FormFilters {
    return {
      legalEntityName: trim,
      dba: trim,
      firstName: trim,
      lastName: trim,
      middleName: trim,
      jobTitle: trim,
    };
  }
}

export default CommercialCustomerInformationStore;
