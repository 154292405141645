import {
  ResidentialSessionInterface,
  ResidentialUtilityAccountInformation,
} from 'services/retail/residential/types';
import { FormFilters, FormValidators } from 'utils/form/types';
import { action, observable } from 'mobx';
import { isAccountNumber, isFullZipCode, isRequired, maxStringLength } from 'utils/form/validators';
import { filterValue, filterValues, validateValue, validateValues } from 'utils/form';
import { State } from 'services/delivery/types';
import { isAccountNumberOnType, isFullZipCodeOnType } from 'utils/form/asserts';
import { trim } from 'utils/form/filters';

const initialForm: ResidentialUtilityAccountInformation = {
  address: '',
  extendedAddress: '',
  city: '',
  county: '',
  state: null,
  zipCode: '',
  accountNumber: '',
};

type Errors = Partial<ResidentialUtilityAccountInformation>;
type Values = string & number & State;
type Validators = FormValidators<ResidentialUtilityAccountInformation>;

class ResidentialUtilityAccountInformationStore {
  data = observable({
    form: initialForm,
    errors: {},
  });

  restoreFromSession = action((residentialSession: ResidentialSessionInterface) => {
    const utilityAccountInformation = residentialSession.getUtilityAccountInformation();
    this.data.form = utilityAccountInformation ? utilityAccountInformation : initialForm;
  });

  onChange = (key: keyof ResidentialUtilityAccountInformation) =>
    action((value: Values) => {
      if (
        (key === 'zipCode' && !isFullZipCodeOnType(value)) ||
        (key === 'accountNumber' && !isAccountNumberOnType(value))
      ) {
        return;
      }
      this.data.form[key] = value;
      this.validateProp(key);
    });

  get hasErrors(): boolean {
    const [isValid] = validateValues<Errors>(this.validators, this.filterForm());
    return !isValid;
  }

  get isValid(): boolean {
    const [isValid, errors] = validateValues<Errors>(this.validators, this.filterForm());
    this.data.errors = errors;

    return isValid;
  }

  get form(): ResidentialUtilityAccountInformation {
    return this.data.form;
  }

  get errors(): Errors {
    return this.data.errors;
  }

  validateProp = action((prop: keyof ResidentialUtilityAccountInformation) => {
    const [, error] = validateValue<ResidentialUtilityAccountInformation>(
      this.validators[prop],
      this.filterProp(prop)
    );
    this.data.errors[prop] = String(error);
  });

  public filterForm = (): ResidentialUtilityAccountInformation => {
    return filterValues<ResidentialUtilityAccountInformation>(this.filters, this.form);
  };

  protected filterProp = (prop: string): any => {
    return this.form[prop] ? filterValue(this.filters[prop], this.form[prop]) : this.form[prop];
  };

  private get validators(): Validators {
    return {
      address: [isRequired(), maxStringLength(90)],
      city: [isRequired(), maxStringLength(60)],
      county: [maxStringLength(60)],
      state: isRequired(),
      zipCode: isFullZipCode(),
      accountNumber: [isRequired(), isAccountNumber()],
      extendedAddress: maxStringLength(60),
    };
  }

  private get filters(): FormFilters {
    return {
      address: trim,
      city: trim,
      county: trim,
      extendedAddress: trim,
    };
  }
}

export default ResidentialUtilityAccountInformationStore;
