import { Plan } from 'services/quotes/types';
import { ResidentialSessionInterface } from 'services/retail/residential/types';
import { AnnualUsage } from 'services/orders/types';

class ResidentialOrderStore {
  constructor(private readonly residentialSession: ResidentialSessionInterface) {}

  isValidSession = (activeStep: number): boolean => {
    switch (activeStep) {
      case 1:
        return (
          this.hasSelectedPlan() &&
          this.hasUtilityAccountInformation() &&
          this.hasMailingBillingInformation()
        );
      case 2:
      case 3:
        return (
          this.hasSelectedPlan() &&
          this.hasCustomerInformation() &&
          this.hasUtilityAccountInformation() &&
          this.hasMailingBillingInformation()
        );
      default:
        return false;
    }
  };

  get annualUsage(): AnnualUsage {
    return this.residentialSession.getAnnualUsage();
  }

  hasSelectedPlan = (): boolean => {
    return !!this.residentialSession.getSelectedPlan();
  };

  hasCustomerInformation = (): boolean => {
    return !!this.residentialSession.getCustomerInformation();
  };

  hasUtilityAccountInformation = (): boolean => {
    return !!this.residentialSession.getUtilityAccountInformation();
  };

  hasMailingBillingInformation = (): boolean => {
    return !!this.residentialSession.getMailingBillingInformation();
  };

  getSelectedPlan = (): Plan => {
    return this.residentialSession.getSelectedPlan();
  };

  getBackUrl = (): string => {
    return this.residentialSession.getBackUrl();
  };
}

export default ResidentialOrderStore;
