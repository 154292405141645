import { ResidentialSessionInterface } from 'services/retail/residential/types';
import ResidentialUtilityAccountInformationStore from 'stores/retail/residential/ResidentialUtilityAccountInformationStore';
import ResidentialMailingBillingInformationStore from 'stores/retail/residential/ResidentialMailingBillingInformationStore';
import { action, observable } from 'mobx';
import UploadBillStore from 'stores/retail/UploadBillStore';
import { BillSamples } from 'services/delivery/types';
import { triggerRetailAddMailingInfoEvent } from 'config/seo/events';

class ResidentialFormsStore {
  constructor(
    private readonly residentialSession: ResidentialSessionInterface,
    private readonly residentialUtilityAccountInformationStore: ResidentialUtilityAccountInformationStore,
    private readonly residentialMailingBillingInformationStore: ResidentialMailingBillingInformationStore,
    public readonly uploadBillStore: UploadBillStore
  ) {}

  billingAddressForm = observable({
    show: false,
  });

  restoreFromSession = action(() => {
    this.residentialUtilityAccountInformationStore.restoreFromSession(this.residentialSession);
    this.residentialMailingBillingInformationStore.restoreFromSession(this.residentialSession);
    this.showBillingAddressForm(this.residentialSession.hasMailingBillingInformation());
  });

  onSubmit = (onSuccess: { (): void }): void => {
    if (this.isValid) {
      this.residentialSession.setUtilityAccountInformation(
        this.residentialUtilityAccountInformationStore.filterForm()
      );
      if (this.billingAddressForm.show) {
        this.residentialSession.setMailingBillingInformation(
          this.residentialMailingBillingInformationStore.filterForm()
        );
        triggerRetailAddMailingInfoEvent();
      }
      onSuccess();
    }
  };

  get hasErrors(): boolean {
    const hasErrors1 = this.residentialUtilityAccountInformationStore.hasErrors;
    const hasErrors2 =
      !this.billingAddressForm.show || this.residentialMailingBillingInformationStore.hasErrors;

    return hasErrors1 || hasErrors2;
  }

  get isValid(): boolean {
    const uploadedBills = this.residentialSession.getUploadedBills();
    const isValid1 = this.residentialUtilityAccountInformationStore.isValid;
    const isValid2 =
      !this.billingAddressForm.show || this.residentialMailingBillingInformationStore.isValid;
    const isValidForm = isValid1 && isValid2;

    if (!uploadedBills || !uploadedBills.length) {
      this.uploadBillStore.setErrorMessage('At least one bill is required');
      return false;
    }

    return isValidForm;
  }

  get isShowBillingAddressForm(): boolean {
    return !this.billingAddressForm.show;
  }

  showBillingAddressForm = action((value: boolean) => {
    if (!value) {
      this.residentialMailingBillingInformationStore.clearForm();
      this.residentialSession.setMailingBillingInformation(
        this.residentialMailingBillingInformationStore.filterForm()
      );
    }
    this.billingAddressForm.show = value;
  });

  get billSamples(): BillSamples {
    return this.residentialSession.getBillSamples();
  }
}

export default ResidentialFormsStore;
