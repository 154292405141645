import { Commodity } from 'services/delivery/types';

export const PLANS_PAGE_SIZE = 10;
export const PLANS_DEFAULT_SORT = 'price,asc';
export const PLANS_DEFAULT_ANNUAL_USAGE = {
  [Commodity.ELECTRICITY]: 10000,
  [Commodity.GAS]: 1000,
};
export const PLANS_PREFERRED_TERMS = '12,18,24,36';
export const ALL_PLANS_TERMS = '6,12,18,24,36,60';
