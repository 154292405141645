import { createRetailViewPlansParams, triggerRetailGetCustomQuoteEvent } from 'config/seo/events';
import InquireFormStore from 'stores/retail/InquireFormStore';
import PlansStore from 'stores/retail/PlansStore';

class CustomQuoteFormStore extends InquireFormStore {
  constructor(private readonly plansStore: PlansStore) {
    super();
  }

  protected beforeSubmit = (formData: FormData) => {
    const filter: Record<string, any> = createRetailViewPlansParams(this.plansStore.createViewPlansEventParams());
    const payload = {
      link: location.href,
      utilityName: filter.utility,
      zipCode: filter.zip_code,
      customerType: filter.customer_type,
    }
    Object.entries(payload).forEach(([key, value]) => formData.append(key, value as string));
  };

  onSuccessSubmit = () => {
    triggerRetailGetCustomQuoteEvent(this.plansStore.createViewPlansEventParams());
  };
}

export default CustomQuoteFormStore;
