import QuotesStore from 'stores/retail/QuotesStore';
import CommercialCustomerInformationStore from 'stores/retail/commercial/CommercialCustomerInformationStore';
import CommercialOrderStore from 'stores/retail/commercial/CommercialOrderStore';
import ResidentialCustomerInformationStore from 'stores/retail/residential/ResidentialCustomerInformationStore';
import ResidentialOrderStore from 'stores/retail/residential/ResidentialOrderStore';
import ResidentialUtilityAccountInformationStore from 'stores/retail/residential/ResidentialUtilityAccountInformationStore';
import ResidentialMailingBillingInformationStore from 'stores/retail/residential/ResidentialMailingBillingInformationStore';
import ResidentialFormsStore from 'stores/retail/residential/ResidentialFormsStore';
import CommercialUtilityAccountInformationStore from 'stores/retail/commercial/CommercialUtilityAccountInformationStore';
import UploadBillStore from 'stores/retail/UploadBillStore';
import CommercialMailingBillingAddressStore from 'stores/retail/commercial/CommercialMailingBillingAddressStore';
import ResidentialConfirmStore from 'stores/retail/residential/ResidentialConfirmStore';
import CommercialConfirmStore from 'stores/retail/commercial/CommercialConfirmStore';
import StoreContainer from 'utils/di/StoreContainer';
import PlansFilterStore from 'stores/retail/PlansFilterStore';
import PlansStore from 'stores/retail/PlansStore';
import StayUpdateFormStore from 'stores/retail/StayUpdateFormStore';
import CustomQuoteFormStore from 'stores/retail/CustomQuoteFormStore';
import InquireFormStore from 'stores/retail/InquireFormStore';

class RetailStoreContainer extends StoreContainer {
  get commercialOrderStore(): CommercialOrderStore {
    const { commercialSession } = this.services;
    return this.getInstance(
      'commercialOrderStore',
      () => new CommercialOrderStore(commercialSession)
    );
  }

  get commercialCustomerInformationStore(): CommercialCustomerInformationStore {
    const { commercialSession } = this.services;

    return this.getInstance(
      'commercialCustomerInformationStore',
      () => new CommercialCustomerInformationStore(commercialSession)
    );
  }

  get commercialUtilityAccountInformationStore(): CommercialUtilityAccountInformationStore {
    const { commercialSession, fileService } = this.services;
    return this.getInstance(
      'commercialUtilityAccountInformationStore',
      () =>
        new CommercialUtilityAccountInformationStore(
          commercialSession,
          new UploadBillStore(commercialSession, fileService)
        )
    );
  }

  get commercialMailingBillingAddressStore(): CommercialMailingBillingAddressStore {
    const { commercialSession } = this.services;

    return this.getInstance(
      'commercialMailingBillingAddressStore',
      () => new CommercialMailingBillingAddressStore(commercialSession)
    );
  }

  get commercialConfirmStore(): CommercialConfirmStore {
    const { commercialSession, ordersService } = this.services;

    return this.getInstance(
      'commercialConfirmStore',
      () => new CommercialConfirmStore(ordersService, commercialSession)
    );
  }

  get residentialOrderStore(): ResidentialOrderStore {
    const { residentialSession } = this.services;
    return this.getInstance(
      'residentialOrderStore',
      () => new ResidentialOrderStore(residentialSession)
    );
  }

  get residentialCustomerInformationStore(): ResidentialCustomerInformationStore {
    const { residentialSession } = this.services;
    return this.getInstance(
      'residentialCustomerInformationStore',
      () => new ResidentialCustomerInformationStore(residentialSession)
    );
  }

  get residentialUtilityAccountInformationStore(): ResidentialUtilityAccountInformationStore {
    return this.getInstance(
      'residentialUtilityAccountInformationStore',
      () => new ResidentialUtilityAccountInformationStore()
    );
  }

  get residentialMailingBillingInformationStore(): ResidentialMailingBillingInformationStore {
    return this.getInstance(
      'residentialMailingBillingInformationStore',
      () => new ResidentialMailingBillingInformationStore()
    );
  }

  get residentialFormsStore(): ResidentialFormsStore {
    const { residentialSession, fileService } = this.services;
    return this.getInstance(
      'residentialFormsStore',
      () =>
        new ResidentialFormsStore(
          residentialSession,
          this.residentialUtilityAccountInformationStore,
          this.residentialMailingBillingInformationStore,
          new UploadBillStore(residentialSession, fileService)
        )
    );
  }

  get residentialConfirmStore(): ResidentialConfirmStore {
    const { residentialSession, ordersService } = this.services;

    return this.getInstance(
      'residentialConfirmStore',
      () => new ResidentialConfirmStore(ordersService, residentialSession)
    );
  }

  get quotesStore(): QuotesStore {
    const { quotesService, deliveryService, commercialSession, residentialSession } = this.services;

    return this.getInstance(
      'quotesStore',
      () => new QuotesStore(quotesService, deliveryService, commercialSession, residentialSession)
    );
  }

  get inquireFormStore(): InquireFormStore {
    return this.getInstance('inquireFormStore', () => new InquireFormStore());
  }

  get customQuoteFormStore(): CustomQuoteFormStore {
    return this.getInstance(
      'customQuoteFormStore',
      () => new CustomQuoteFormStore(this.plansStore)
    );
  }

  get plansFilterStore(): PlansFilterStore {
    const { deliveryService } = this.services;

    return this.getInstance('plansFilterStore', () => new PlansFilterStore(deliveryService));
  }

  get plansStore(): PlansStore {
    const { quotesService, commercialSession, residentialSession } = this.services;

    return this.getInstance(
      'plansStore',
      () =>
        new PlansStore(this.plansFilterStore, quotesService, commercialSession, residentialSession)
    );
  }

  get stayUpdateFormStore(): StayUpdateFormStore {
    return this.getInstance('stayUpdateFormStore', () => new StayUpdateFormStore(this.plansStore));
  }
}

export default RetailStoreContainer;
