import { FieldListProps } from 'components/common/structures/FieldList/FieldList';
import {
  CreateCommercialOrderRequest,
  CustomerType,
  OrderLineItem,
  OrdersServiceInterface,
  ServiceType,
} from 'services/orders/types';
import { action, observable } from 'mobx';
import { CommercialSessionInterface } from 'services/retail/commercial/types';
import { HttpStatus } from 'services/http/types';
import { unitByAnnualUsage } from 'utils/data';
import { handleError } from 'utils/errors';

class CommercialConfirmStore {
  order = observable({
    isLoading: false,
    errorMessage: '',
  });

  constructor(
    private readonly ordersService: OrdersServiceInterface,
    private readonly commercialSession: CommercialSessionInterface
  ) {}

  get isLoading(): boolean {
    return this.order.isLoading;
  }

  get errorMessage(): string {
    return this.order.errorMessage;
  }

  clearErrorMessage = action(() => {
    this.order.errorMessage = '';
  });

  clearSession = (): void => {
    this.commercialSession.clear();
  };

  hasStartedSession = (): boolean => {
    return this.commercialSession.hasStarted();
  };

  makeInformationList = (): FieldListProps[] => {
    const result = [...this.makeCustomerInformation(), ...this.makeUtilityAccountInformation()];
    if (this.commercialSession.hasUploadedBills()) {
      // @ts-ignore
      result.push(this.makeUploadedBills());
    }
    result.push(this.makeMailingBillingInformation());

    // @ts-ignore
    return result;
  };

  createOrder = action(async (onSuccess: () => void) => {
    const request = this.makeRequest();
    this.order.isLoading = true;
    try {
      await this.ordersService.createCommercialOrder(request);
      onSuccess();
    } catch (e) {
      handleError(e);
      const { status } = e?.response;
      this.order.errorMessage =
        status === HttpStatus.UNPROCESSABLE_ENTITY
          ? "confirmation.validation-error"
          : `confirmation.server-error`;
    } finally {
      this.order.isLoading = false;
    }
  });

  private makeRequest = (): CreateCommercialOrderRequest => {
    const { getCustomerInformation, getMailingBillingInformation } = this.commercialSession;
    const { firstName, middleName, lastName, email, phone, dba, jobTitle, legalEntityName } =
      getCustomerInformation();
    const { address, city, state, county, zipCode, extendedAddress } =
      getMailingBillingInformation();

    return {
      serviceType: ServiceType.RETAIL_ENERGY,
      customerInfo: {
        type: CustomerType.COMMERCIAL,
        firstName,
        lastName,
        middleName,
        emailAddress: email,
        phoneNumber: phone,
        legalEntity: legalEntityName,
        jobTitle,
        dba,
        mailingAddress: {
          address,
          city,
          county: county,
          state: state.state,
          zipCode,
          extendedAddress,
        },
      },
      orderLineItems: this.makeOrderLineItems(),
    };
  };

  private makeOrderLineItems = (): OrderLineItem[] => {
    const { getUtilityAccountInformation, getSelectedPlan, getAnnualUsage, getUploadedBills } =
      this.commercialSession;
    const { planId } = getSelectedPlan();
    const utilityAccounts = getUtilityAccountInformation();
    const defaultAnnualUsage = getAnnualUsage();
    const uploadedBills = getUploadedBills() || [];

    return [
      {
        planId,
        utilityAccounts: utilityAccounts.map((utilityAccount) => {
          const {
            address,
            city,
            state,
            county,
            zipCode,
            accountNumber,
            extendedAddress,
            annualUsage,
          } = utilityAccount;

          return {
            accountNumber,
            serviceAddress: {
              address,
              city,
              county: county,
              extendedAddress,
              state: state.state,
              zipCode,
            },
            annualUsage: annualUsage
              ? { amount: Number(annualUsage), unit: defaultAnnualUsage.unit }
              : defaultAnnualUsage,
          };
        }),
        fileIds: uploadedBills.map((file) => file.id),
      },
    ];
  };

  private makeCustomerInformation = () => {
    const { getCustomerInformation, getEffectiveDate } = this.commercialSession;
    const { firstName, middleName, lastName, email, phone, legalEntityName, jobTitle, dba } =
      getCustomerInformation();
    const effectiveDate = getEffectiveDate();

    return [
      {
        header: 'retail.customer-info',
        items: [
          { title: 'confirmation.legal-name', value: legalEntityName },
          { title: 'retail.dba', value: dba },
          { title: 'confirmation.contract-start-date', value: effectiveDate },
        ],
      },
      {
        header: 'retail.decision-maker',
        items: [
          { title: 'form.label.name', value: `${firstName} ${middleName} ${lastName}` },
          { title: 'form.label.phone', value: phone },
          { title: 'form.label.email', value: email },
          { title: 'confirmation.job-title', value: jobTitle },
        ],
      },
    ];
  };

  private makeUtilityAccountInformation = () => {
    const { getUtilityAccountInformation, getAnnualUsage } = this.commercialSession;
    const utilityAccounts = getUtilityAccountInformation();
    const defaultAnnualUsage = getAnnualUsage();
    const unit = unitByAnnualUsage(defaultAnnualUsage, true);

    return utilityAccounts.map((utilityAccount, index) => {
      const { address, extendedAddress, city, state, county, zipCode, accountNumber, annualUsage } =
        utilityAccount;
      const numeration = utilityAccounts.length > 1 ? ` ${index + 1}` : '';
      return {
        header: `Utility Account Information ${numeration}`,
        items: [
          {
            title: 'confirmation.service-address',
            value: `${address}, ${extendedAddress || ''} ${city}, ${county || ''} 
            ${state.state} ${zipCode}`,
          },
          { title: 'confirmation.account-number', value: accountNumber },
          {
            title: 'confirmation.annual-usage',
            value: `${Number(annualUsage).toLocaleString('en-US')} ${unit}`,
          },
        ],
      };
    });
  };

  private makeUploadedBills = () => {
    const { getUploadedBills } = this.commercialSession;
    const uploadedBills = getUploadedBills();

    return {
      header: `confirmation.files`,
      items: [
        {
          title: 'confirmation.uploaded-bills',
          value: uploadedBills.map(({ name }) => name),
        },
      ],
    };
  };

  private makeMailingBillingInformation = () => {
    const { getMailingBillingInformation } = this.commercialSession;
    const { address, extendedAddress, city, state, county, zipCode } =
      getMailingBillingInformation();

    return {
      header: 'confirmation.mailing-billing',
      items: [
        {
          title: 'form.label.address',
          value: `${address}, ${extendedAddress || ''} ${city}, ${county || ''} 
          ${state.state} ${zipCode}`,
        },
      ],
    };
  };
}

export default CommercialConfirmStore;
