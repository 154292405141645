import { action, observable } from 'mobx';
import CommercialUtilityStore from 'stores/retail/commercial/CommercialUtilityStore';
import { CommercialSessionInterface } from 'services/retail/commercial/types';
import UploadBillStore from 'stores/retail/UploadBillStore';
import { unitByAnnualUsage } from 'utils/data';
import { AnnualUsageUnit } from 'services/orders/types';

class CommercialUtilityAccountInformationStore {
  data = observable({
    utilityStores: [],
    errorMessage: '',
  });

  constructor(
    public readonly commercialSession: CommercialSessionInterface,
    public readonly uploadBillStore: UploadBillStore
  ) {}

  restoreFromSession = action(() => {
    const utilityAccountInformation = this.commercialSession.getUtilityAccountInformation();
    const checkedUtilityAccountIndex = this.commercialSession.getCheckedUtilityAccountIndex();
    if (utilityAccountInformation) {
      this.utilityStores = utilityAccountInformation.map((store, index) => {
        const form = CommercialUtilityStore.createFrom(store, this.commercialSession);
        if (checkedUtilityAccountIndex === index) {
          form.isBillingAddress = true;
        }
        return form;
      });
    }
  });

  checkBillingAddress = action((storeId: string) => {
    this.utilityStores.forEach((utilityStore) => {
      if (utilityStore.storeId === storeId) {
        utilityStore.isBillingAddress = !utilityStore.isBillingAddress;
      } else {
        utilityStore.isBillingAddress = false;
      }
    });
  });

  onSubmit = action((onSuccess: { (): void }): void => {
    if (this.isValid) {
      const { unit, minimum, maximum } = this.commercialSession.getSelectedPlan().annualUsage;
      const unitSign = unitByAnnualUsage({ unit: unit as AnnualUsageUnit });
      if (!this.isValidCommonAnnualUsage()) {
        this.setErrorMessage(
          `Summary annual usage must be between ${minimum} ${unitSign} and ${maximum} ${unitSign} inclusive`
        );
        return;
      }

      this.commercialSession.setUtilityAccountInformation(
        this.utilityStores.map(({ filterForm }) => filterForm())
      );
      const checkedUtilityIndex = this.utilityStores.findIndex(
        (utilityStore) => utilityStore.isBillingAddress
      );
      this.commercialSession.setCheckedUtilityAccountIndex(checkedUtilityIndex);
      if (checkedUtilityIndex >= 0) {
        this.commercialSession.setMailingBillingInformation(
          this.utilityStores[checkedUtilityIndex].filterForm()
        );
      }
      onSuccess();
    }
  });

  get errorMessage() {
    return this.data.errorMessage;
  }

  setErrorMessage = action((errorMessage: string) => {
    this.data.errorMessage = errorMessage;
  });

  clearErrorMessage = action(() => {
    this.data.errorMessage = '';
  });

  isValidCommonAnnualUsage = (): boolean => {
    const { minimum, maximum } = this.commercialSession.getSelectedPlan().annualUsage;
    return minimum <= this.commonAnnualUsage && this.commonAnnualUsage <= maximum;
  };

  get commonAnnualUsage() {
    return this.utilityStores.reduce(
      (sum, utility) => parseInt(utility.form.annualUsage, 10) + sum,
      0
    );
  }

  get isValid(): boolean {
    const uploadedBills = this.commercialSession.getUploadedBills();
    const isValidForm = this.utilityStores.reduce<boolean>(
      (isValid, store) => store.isValid && isValid,
      true
    );
    if (!uploadedBills || !uploadedBills.length) {
      this.uploadBillStore.setErrorMessage('At least one bill is required');
      return false;
    }
    return isValidForm;
  }

  get utilityStores(): CommercialUtilityStore[] {
    return this.data.utilityStores;
  }

  set utilityStores(utilityStores: CommercialUtilityStore[]) {
    this.data.utilityStores = utilityStores;
  }

  addUtility = action(() => {
    this.utilityStores = [
      ...this.utilityStores,
      CommercialUtilityStore.createFrom(
        {
          state: this.utilityStores[0].form.state,
          zipCode: this.utilityStores[0].form.zipCode,
        },
        this.commercialSession
      ),
    ];
  });

  removeUtility = (storeId: string) =>
    action(() => {
      this.utilityStores = this.utilityStores.filter((store) => store.storeId !== storeId);
    });
}

export default CommercialUtilityAccountInformationStore;
